// Libs
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
// Context
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Actions
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useComponentsPool } from '../../../../ComponentsPool';
import createLoadingSelector from '../../../layout/actions';
import BarrierCone from '../../../layout/assets/barrier-cone.svg';
// Helpers
import { createTicket, updateTicket } from '../../../tickets/actions';
import { getAnimationOrImgByIncident } from '../../helper';
import {
    EVENT_CLICK_QRCODE_INCIDENT,
    EVENT_CREATE_QRCODE_TICKET, EVENT_LET_USER_MAIL, isValid, segmentTrack
} from '../../../layout/helper';
import { hasEmail } from '../../../users/helper';
import { templatesAtTheEnd } from '../../../templates/reducer';
import QRCodeBackButton from '../QRCodeBackButton';

const loadingSelector = createLoadingSelector(['GET_DEVICE', 'CREATE_TICKET_VIA_DECLARATIVE_QRCODE', 'UPLOAD_COMMENT_DOCUMENT']);

const NewTicketForm = props => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { Component } = useComponentsPool();
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const devicesList = useSelector(state => state.devices.list);
    const [device, setDevice] = useState({});
    const [statePage, setStatePage] = useState('initialization');
    const [ticketData, setTicketData] = useState({});
    const workspaceSettings = useSelector(state => state.workspace.settings);
    const isLogged = useSelector(state => state.authentication.isLogged);
    const qrcodesCurrentTicket = useSelector(state => state.qrcodes.currentQRCodeTicket);
    const [relatedTicket, setRelatedTicket] = useState(qrcodesCurrentTicket || null);
    const currentUser = useSelector(state => state.users.currentUser);
    const isLoggedUserWithEmail = isLogged && hasEmail(currentUser);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [email, setEmail] = useState(isLoggedUserWithEmail ? currentUser?.email : '');
    const disableValidatedButton = ticketData.title === 'error_field' || isEmpty(ticketData.title)
        || ticketData.comment === 'error_field'
        || !isLoggedUserWithEmail && workspaceSettings?.mandatory_mail_in_qrcode && isEmpty(email);
    const [isManualLoading, setIsManualLoadingsLoading] = useState(true);
    const lottieStyles = {
        height: '57%',
        width: 'unset',
        aspectRatio: 1
    };
    const useStyles = makeStyles(() => ({
        paper: {
            height: '100%',
            width: 'inherit',
            justifyContent: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'transparent',
            boxShadow: 'none'
        }
    }));
    const classes = useStyles();

    const handleChangeEmail = value => {
        if (isValid(value)) {
            setEmail('');
        } else {
            setEmail(value);
        }
    };

    useEffect(() => {
        // Set manual loading to prevent the error page display
        const timer = setTimeout(() => {
            setIsManualLoadingsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (devicesList?.length > 0) {
            const issueReportDevice = devicesList[0];
            // if there is the incident 'other' we put it at the end
            const otherIncidentIdx = issueReportDevice.templates.findIndex(template => templatesAtTheEnd.includes(template?.title));
            if (otherIncidentIdx !== -1) {
                issueReportDevice.templates.push(issueReportDevice.templates.splice(otherIncidentIdx, 1)[0]);
            }
            setDevice(issueReportDevice);
            setStatePage(qrcodesCurrentTicket ? 'validated' : 'not_ready');
        } else { setStatePage('rejected'); }
    }, [devicesList]);

    // default option for the animation
    const getAnimationOption = (pageNumber, template) => ({
        loop: false,
        autoplay: true,
        animationData: getAnimationOrImgByIncident(pageNumber, template, ticketData.color_title)
    });

    const createTicketAction = () => {
        console.log('test-alireza')
        dispatch(
            createTicket({
                origin: 'DECLARATIVE_QRCODE',
                title: ticketData.title,
                comment: ticketData.comment,
                fileType: 'MERCIYANIS_MESSAGE',
                urls: uploadedFiles,
                priority: '0',
                custom_field: {
                    author_device_id: device.device_id?.toString(),
                    batiment_group_id: device.batiment_group?.group_id,
                    floor_group_id: device.floor_group?.group_id,
                    position_group_id: device.position_group?.group_id,
                    skill_group_id: ticketData.skill_group_id,
                    template_id: ticketData.template_id
                }
            }, !isLogged, true)).then(res => {
                console.log("test-alireza", "!isLoggedUserWithEmail", isLoggedUserWithEmail, workspaceSettings?.mandatory_mail_in_qrcode)

                segmentTrack(EVENT_CREATE_QRCODE_TICKET, { qrCodeId: device?.device_id, ticketId: res?.value?.id });
                if (!isLoggedUserWithEmail && workspaceSettings?.mandatory_mail_in_qrcode) {
                    segmentTrack(EVENT_LET_USER_MAIL, { qrCodeId: device.device_id, mailGivenByFollower: email });
                    dispatch(updateTicket(
                        res?.value?.id,
                        {
                            external_followers: [{ mail: email }],
                            is_declarative: true
                        }, true)
                    );
                }
                setStatePage('validated');
                setTicketData({});
                setRelatedTicket(res.value);
            });
    };

    const getMailComponent = () => {
        if (!isLoggedUserWithEmail && workspaceSettings?.mandatory_mail_in_qrcode) {
            return (
                <Component
                    className={'email-input'}
                    componentName={'Input'}
                    rules={['isEmail']}
                    required
                    value={email}
                    startAdornment={<Component componentName={'Icon'} type={'email'} />}
                    label={`${t('common:your_email_address')}`}
                    onChange={value => handleChangeEmail(value.trim())}
                />
            );
        }
        return <></>;
    };

    const addCommentText = `${t('tickets:add_comment')} ${t('common:optional')}`;

    const defaultBackgroundColor = '#ffffffba';
    const getComponent = () => {
        if (isEmpty(ticketData)) {
            switch (statePage) {
                case 'not_ready':
                    if (device.templates?.length > 0) {
                        return (
                            <div className="incident-form-wrapper">
                                <div className="incident-form-title">
                                    {props.qrcodesHasManyMode && <QRCodeBackButton />}
                                    <p>{t('passages:select_incident')}</p>
                                </div>
                                <div className="choice-incidents">
                                    {device.templates?.map(template => (
                                        <div
                                            key={template.id}
                                            style={{
                                                backgroundColor: template.color_title
                                                    ? template.color_title
                                                    : defaultBackgroundColor
                                            }}
                                            className={
                                                classNames([
                                                    'choice-incident',
                                                    template.color_title && 'custom-color-incident',
                                                    (template.custom_field?.type === 'EXTERNAL_LINK' || template.custom_field?.type === 'PHONE_CALLING') && 'external-incident'
                                                ])
                                            }
                                            role={'button'}
                                            tabIndex={0}
                                            onClick={() => {
                                                if (template.custom_field?.type === 'EXTERNAL_LINK' && template.custom_field.external_link) {
                                                    window.location.href = template.custom_field.external_link;
                                                } else if (template.custom_field?.type === 'PHONE_CALLING' && template.custom_field.phone_number) {
                                                    window.location.href = `tel:${template.custom_field.phone_number}`;
                                                } else {
                                                    setTicketData({
                                                        title: template.title === 'Autre' ? '' : template.title,
                                                        skill_group_id: template.groups,
                                                        color_title: template.color_title ? template.color_title : '',
                                                        template_id: template.title === 'Autre' ? '' : template.id
                                                    });
                                                }
                                            }}
                                        >
                                            {
                                                (template.custom_field?.type === 'EXTERNAL_LINK' || template.custom_field?.type === 'PHONE_CALLING')
                                                && (
                                                    <Component
                                                        componentName={'Icon'}
                                                        type={template.custom_field?.type === 'EXTERNAL_LINK'
                                                            ? 'externalLink'
                                                            : 'outcomingPhone'
                                                        }
                                                    />
                                                )
                                            }
                                            <img
                                                className={'incident-img'}
                                                src={getAnimationOrImgByIncident(1, template.icon_title ? template.icon_title : 'other', ticketData.color_title)}
                                                alt={''}
                                            />
                                            <p className="form-text-incident">{template.title}</p>

                                        </div>

                                    ))}
                                </div>
                            </div>
                        );
                    }
                    if (isManualLoading) {
                        return <></>;
                    }
                    return (
                        <Component
                            componentName={'MobileErrorPage'}
                            title={t('passages:qrcode_in_work')}
                            subtitle={t('passages:you_will_be_able_soon')}
                            icon={<BarrierCone />}
                            hasGoBackArrow={props.qrcodesHasManyMode}
                        />
                    );
                case 'validated':
                    return <Component componentName={'NewTicketFormValidationPage'} hasGoBackArrow={props.qrcodesHasManyMode} relatedTicket={relatedTicket} />;
                case 'rejected':
                default:
                    return <Component componentName={'MobileErrorPage'} hasGoBackArrow={props.qrcodesHasManyMode} />;
            }
        } else {
            segmentTrack(EVENT_CLICK_QRCODE_INCIDENT, { qrCodeId: device.device_id });
            return (
                <Slide
                    direction="left"
                    in={!isEmpty(ticketData)}
                    timeout={{
                        enter: 400
                    }}
                >
                    <Paper className={classes.paper} style={{ width: '100%' }}>
                        <div className={'selected-incident-top'}>
                            <span onClick={() => { setTicketData({}); }}><Component componentName={'Icon'} type={'ArrowBackIcon'} /></span>
                            <p className="incident-form-title">{t('passages:selected_incident')}</p>
                        </div>
                        {
                            !ticketData.template_id
                                ? (
                                    <>
                                        <div
                                            style={{ backgroundColor: ticketData.color_title ? ticketData.color_title : defaultBackgroundColor }}
                                            className={classNames(['selected-incident', ticketData.color_title && 'custom-color-incident'])}
                                        >
                                            <Lottie
                                                style={lottieStyles}
                                                options={getAnimationOption(2, 'other', ticketData.color_title)}
                                            />
                                        </div>
                                        <Component
                                            componentName={'Input'}
                                            rules={['maxLength-128']}
                                            className={'other-incident-title-input'}
                                            required
                                            label={t('passages:give_request_title')}
                                            onChange={value => setTicketData({ ...ticketData, title: value })}
                                        />
                                    </>
                                ) : (
                                    <div
                                        style={{ backgroundColor: ticketData.color_title ? ticketData.color_title : defaultBackgroundColor }}
                                        className={classNames(['selected-incident', ticketData.color_title && 'custom-color-incident'])}
                                    >
                                        <Lottie
                                            style={lottieStyles}
                                            options={
                                                getAnimationOption(2, device.templates
                                                    .find(template => template.id === ticketData.template_id)
                                                    ?.icon_title || 'other', ticketData.color_title
                                                )
                                            }
                                        />
                                        <p className="form-text-incident">{ticketData.title}</p>
                                    </div>
                                )
                        }
                        <div className={'incident-form-comment-wrapper'}>
                            <Component
                                componentName={'Input'}
                                rules={['maxLength-512']}
                                multiline={4}
                                label={addCommentText}
                                onChange={value => {
                                    setTicketData({
                                        ...ticketData,
                                        comment: value.trim() === '' ? '' : value.trim()
                                    });
                                }}
                                showAttachmentUploader
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={value => setUploadedFiles(value)}
                                isAnonymous={!isLogged}
                            />
                            {getMailComponent()}
                        </div>
                        <div className={'qrcode-button'}>
                            <Button
                                key={'validate_with_job'}
                                onClick={() => createTicketAction()}
                                disabled={disableValidatedButton}
                            >
                                <p className={'qrcode-button-text'}>{t('passages:validate_passage')}</p>
                            </Button>
                        </div>
                    </Paper>
                </Slide>
            );
        }
    };

    return (
        <div className="incident-page">
            {(isLoading || device.templates?.length <= 0 && isManualLoading)
                && (
                    <Component
                        componentName={'LoaderBarTop'}
                        isLoading={isLoading || device.templates?.length <= 0 && isManualLoading}
                    />
                )
            }
            <div className="content">
                <div className={'form-wrapper-incident'}>
                    {statePage !== 'initialization' && getComponent()}
                </div>
            </div>
        </div>
    );
};

export default NewTicketForm;
