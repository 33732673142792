// Libs
import React, { useEffect } from 'react';
// Context
import classNames from 'classnames';
import { useComponentsPool } from '../../../ComponentsPool';
import useRouter from '../../../utils/hook/useRouter';
import { useSelector } from 'react-redux';

export default () => {
  const { Component } = useComponentsPool();
  const { history } = useRouter();
  const show_devices_failures = useSelector(state => state.workspace.settings).show_devices_failures;
  const isOwner = useSelector(state => state.users.currentUser).workspace_role?.workspace_role_name === 'Owner';

  useEffect(() => {
    // Redirect if view=failures + to
    history.push({ search: `view=list` })
  }, [show_devices_failures])

  const deviceTabs = [
    {
      searchQuery: "list",
      name: "Tous"
    }, {
      searchQuery: "failures",
      name: "Anomalies"
    }
  ]

  return (
    <div className='devices-tables-wrapper'>
      <div className='dashboard-tabs-container'>
        {show_devices_failures && isOwner && deviceTabs.map((tab, idx) => (
          <div
            className={classNames([
              'dashboard-tab',
              { 'selected': tab.searchQuery === new URLSearchParams(window.location.search).get("view") }
            ])}
            onClick={() => history.push({ search: `view=${tab.searchQuery}` })}
            key={idx}
          >
            {tab.name}
          </div>
        ))}
      </div>
      {new URLSearchParams(window.location.search).get("view") !== 'failures'
        ? <Component componentName={'DevicesTable'} onRowClick={() => { }} />
        : (
          <iframe
            className='devices-failures-wrapper'
            id="inlineFrameExample"
            title="Inline Frame Example"
            frameBorder="0"
            src={`https://manage.merciyanis.com/device-alerts?token=${localStorage.getItem('iot_rocket_access_JWT')}&workspace=${window.location.hostname.split('.')[0]}`}
          >
          </iframe>
        )
      }
    </div>
  );
};
