// Libs
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { isMobile } from 'react-device-detect';
import { isEqual, isEmpty, cloneDeep, includes, every } from 'lodash';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import { useIsOnline } from 'react-use-is-online';
import { useModal } from '../modal/ModalContext';
import { addFootbar } from '../footbar/actions';

// Components
import DroppableColumn from './components/DroppableColumn';
// Actions
import {
    moveTicket,
    updateTicket,
    saveTicketOrder,
    setTicketShownInDetailPopup,
    getTicketSummary,
    getTicketComments,
    addTicketEditableField,
    getTicketOperations,
    setPlayingSoundOff,
    getTickets,
    setVisibleTickets,
    getTicketFromKey,
    moveTickets,
    updateTickets, ticketsMoveDiffColErrorNotif, networkConnectionErrorNotif
} from './actions';
import { useComponentsPool } from '../../ComponentsPool';
// SCSS
import './style.scss';

import createLoadingSelector from '../layout/actions';
import { Toast } from '../notification/components';
import { useFootBar } from '../footbar/FootBarContext';
import { updateNotification } from '../navbar/actions';
import { EVENT_OPEN_TICKET, segmentTrack } from '../layout/helper';
import { ticketMovementType } from './enums';
import { notification } from '../notification/actions';


const loadingSelector = createLoadingSelector(['GET_TICKETS', 'GET_TICKET_FROM_KEY', 'SAVE_TICKET_ORDER', 'UPDATE_TICKET', 'UPDATE_TICKETS', 'MOVE_TICKETS']);
const loadingSelectorOnMobile = createLoadingSelector(['UPDATE_TICKET']);
const Tickets = () => {
    const [t] = useTranslation();
    const { Component } = useComponentsPool();
    const dispatch = useDispatch();
    const { isOnline } = useIsOnline();
    const tickets = useSelector(state => state.tickets.list);
    const notifications = useSelector(state => state.navbar.notificationsList);
    const [width] = useState(`${100 / tickets.length}%`);
    const [ticketMovement, setTicketMovement] = useState({ movement_type: "", destination: "" });
    const notification_sound = useSelector(state => state.workspace.settings.notification_sound);
    const playingSound = useSelector(state => state.users.playingSound);
    const isLoading = useSelector(state => loadingSelector(state.layout.loading));
    const isLoadingOnMobile = useSelector(state => loadingSelectorOnMobile(state.layout.loading));
    const modal = useModal();
    const groupList = useSelector(state => state.groups.list);
    const footbar = useFootBar();
    const ticketsActiveMode = useSelector(state => state.tickets.list.find(category => category?.id === 'ARCHIVED')?.isVisible === false);
    const [hasLoadedArchivedTicketOnce, setHasLoadedArchivedTicketOnce] = useState(false);
    const currentUser = useSelector(state => state.users.currentUser);
    const currentUserRole = useSelector(state => state.users.currentUser.workspace_role?.workspace_role_name);

    if (!isMobile) {
        const routes = useSelector(state => state.footbar.routes);
        if (!routes.find(route => route.path === '/tickets' || route.path === '/tickets/:key')) {
            dispatch(addFootbar({ path: '/tickets', component: 'TicketFootbar' }));
            dispatch(addFootbar({ path: '/tickets/:key', component: 'TicketFootbar' }));
        }
    }

    const workspaceSettings = useSelector(state => state.workspace.settings);
    const modeFloorIsOpen = workspaceSettings.mode_floor !== 'close';
    const modePositionIsOpen = workspaceSettings.mode_position !== 'close';
    const required_fields = workspaceSettings.required_fields || '';
    const editableFields = useSelector(state => state.tickets.editableFields);

    // Display supervision mode only if isSupervisorMode is True
    // && current user is USER
    // && and he has supervision skills
    const isSupervisorMode = currentUserRole === 'User'
        && workspaceSettings.supervision_skills_to_users
        && currentUser.group_memberships.some(group => group?.group?.custom_field?.type === 'SKILL');
    const [supervisorMode, setSupervisorMode] = useState('my_tickets');

    if (!editableFields.find(editableField => editableField.name === 'ticket_floor')) {
        dispatch(addTicketEditableField({
            name: 'ticket_floor',
            title: 'tickets:floor',
            componentName: 'EditableField',
            renderValue: 'TicketSimpleViewGroup',
            editionComponent: modeFloorIsOpen ? 'TicketInputShowGroupName' : 'GroupSelector',
            props: {
                required: required_fields.includes('floor'),
                rules: required_fields.includes('floor') ? ['maxLength-64', 'selectNotEmpty'] : ['maxLength-64'],
                editedValue: modeFloorIsOpen ? ['custom_field', 'floor_label'] : ['custom_field', 'floor_group_id'],
                value: modeFloorIsOpen ? ['custom_field', 'floor_label'] : ['custom_field', 'floor_group_id'],
                placeholder: '',
                groupType: 'FLOOR',
                modeHierarchy: true,
                parent: ['custom_field', 'batiment_group_id'],
                object: 'ticket',
                multiple: false
            },
            weight: 20
        }));
    }
    if (!editableFields.find(editableField => editableField.name === 'ticket_position')) {
        dispatch(addTicketEditableField({
            name: 'ticket_position',
            title: 'tickets:position',
            renderValue: 'TicketSimpleViewGroup',
            editionComponent: modePositionIsOpen ? 'TicketInputShowGroupName' : 'GroupSelector',
            props: {
                required: required_fields.includes('position'),
                rules: required_fields.includes('position') ? ['maxLength-64', 'selectNotEmpty'] : ['maxLength-64'],
                editedValue: modePositionIsOpen ? ['custom_field', 'position_label'] : ['custom_field', 'position_group_id'],
                value: modePositionIsOpen ? ['custom_field', 'position_label'] : ['custom_field', 'position_group_id'],
                placeholder: '',
                groupType: 'POSITION',
                modeHierarchy: true,
                parent: ['custom_field', 'floor_group_id'],
                object: 'ticket'
            },
            weight: 30
        }));
    }

    const location = useLocation();

    useEffect(() => {
        if (!queryString.parse(location.search)?.key) {
            dispatch(setVisibleTickets('DEFAULT')); // by default we display the active ticket's page
        }
    }, []);

    useEffect(async () => {
        const filters = queryString.parse(location.search);
        if (filters.key) {
            let ticket;
            await dispatch(getTicketFromKey(filters.key)).then(res => { ticket = res.action.payload.data; });
            if (ticket) {
                segmentTrack(EVENT_OPEN_TICKET, { ticketId: ticket?.id, ticketOpenedByClickingOnIt: false });
                if (!ticket.active) {
                    if (!isMobile) { // We can only see archived tickets on PC
                        dispatch(setVisibleTickets('ARCHIVED'));
                        // We only retrieve the archived tickets once
                        if (!hasLoadedArchivedTicketOnce && tickets.find(cat => cat.id === 'ARCHIVED')?.tickets.length === 0) {
                            dispatch(getTickets(false, isSupervisorMode, supervisorMode));
                            setHasLoadedArchivedTicketOnce(true);
                        }
                    } else {
                        window.history.replaceState({}, '', '/tickets');
                        return toast(
                            <Toast
                                isConfirm
                                message={t('notifications:not_found_ticket_key')}
                                icon={'frown'}
                                type={'error'}
                            />, {
                            position: toast.POSITION.BOTTOM_LEFT,
                            className: 'normal',
                            bodyClassName: 'notification-body grow-font-size',
                            progressClassName: 'error-custom-progress-bar'
                        }
                        );
                    }
                }
                const ticketNotifs = notifications.flat()
                    .filter(notif => notif.object?.related_ticket.key === ticket.key);
                // Mark as "read" every notif related to the opened ticket
                if (ticketNotifs && ticket.key) {
                    ticketNotifs
                        .filter(notif => !notif.seen_at)
                        .forEach(notif => dispatch(updateNotification(notif.id, { seen_at: moment().format() })));
                }
                await dispatch(setTicketShownInDetailPopup(ticket, groupList));
                await dispatch(getTicketSummary(ticket?.id, 1));
                await dispatch(getTicketComments(ticket?.id, 1));
                await dispatch(getTicketOperations(ticket?.id, 1));
                modal.update({ name: 'TicketDetailsPopup', item: ticket, withFootBar: true });
            } else {
                toast(
                    <Toast
                        isConfirm
                        message={t('notifications:not_found_ticket_key')}
                        icon={'frown'}
                        type={'error'}
                    />, {
                    position: toast.POSITION.BOTTOM_LEFT,
                    className: 'normal',
                    bodyClassName: 'notification-body grow-font-size',
                    progressClassName: 'error-custom-progress-bar'
                }
                );
                window.history.replaceState({}, '', '/tickets');
            }
        }
    }, [location]);

    const onDragEnd = async result => {
        if (isOnline) {
            const { source, destination, draggableId: draggedTicketId } = result;
            const checkedTicketIDs = footbar.getSelected();
            const checkedTicketObjects = footbar.getList();

            // If the dragged ticket moved to another col or row and has a destination (successful drag case)
            if (!isEqual(source, destination) && !isEmpty(destination)) {
                // dragged ticket exists in the checked tickets (ID) list
                const isGroupDrag = includes(checkedTicketIDs, draggedTicketId);

                if (!isGroupDrag || (isGroupDrag && checkedTicketIDs.length === 1)) { // Single element drag
                    await dispatch(moveTicket({ source, destination, draggedTicketId }, tickets));
                    if (source.droppableId !== destination.droppableId) {
                        dispatch(updateTicket(draggedTicketId, { status: destination.droppableId }));
                    }
                    // On n'enregistre pas l'ordre des tickets si un ticket est déplacé dans la colonne terminé
                    if (destination.droppableId !== 'COMPLETED' || source.droppableId === destination.droppableId) {
                        setTicketMovement({ movement_type: ticketMovementType.SINGLE_TICKET, destination: '' });
                    }
                    if (!isEmpty(footbar.getList().filter((ticket) => ticket?.id === draggedTicketId))) {
                        const selectedTickets = cloneDeep(footbar.getList());
                        selectedTickets?.forEach((ticket) => {
                            if (ticket?.id === draggedTicketId) ticket.status = destination.droppableId;
                        });
                        footbar.update({
                            selected: selectedTickets.map((t) => t?.id),
                            list: selectedTickets,
                            type: 'ticket'
                        });
                    }
                } else if (isGroupDrag && checkedTicketIDs.length > 1) {
                    // Group elements drag
                    const isSameColumn = every(checkedTicketObjects, (ticket) => ticket?.status === source.droppableId);
                    if (!isSameColumn) {
                        dispatch(ticketsMoveDiffColErrorNotif());
                        return;
                    }
                    dispatch(moveTickets({ source, destination, checkedTicketIDs }, tickets));
                    // Update the api from useEffect
                    setTicketMovement({ movement_type: ticketMovementType.MULTIPLE_TICKETS, destination: destination.droppableId });
                }
            }
        } else {
            dispatch(notification(t('notifications:update_ticket_error'), 'error', 'frown'));
        }
    };


    useEffect(() => {
        if (ticketMovement.movement_type === ticketMovementType.SINGLE_TICKET) {
            dispatch(saveTicketOrder(tickets));
            setTicketMovement({ movement_type: '', destination: '' });
        }
        if (ticketMovement.movement_type === ticketMovementType.MULTIPLE_TICKETS) {
            const checkedTickets = footbar.getList();
            dispatch(updateTickets(checkedTickets, ticketMovement.destination, tickets))
                .then(() => {
                    // update selected tickets status in footbar
                    const selectedTickets = cloneDeep(checkedTickets);
                    selectedTickets?.forEach((ticket) => {
                        ticket.status = ticketMovement.destination;
                    });
                    footbar.update({
                        selected: selectedTickets.map((t) => t?.id),
                        list: selectedTickets,
                        type: 'ticket'
                    });
                })
                .catch((error) => {
                    if (error.message === 'Network Error') {
                        dispatch(networkConnectionErrorNotif());
                        // reload page after 3 seconds ( no cache ) => user can see that there is no network connection
                        // it will work if state is not persisted
                        setTimeout(() => {
                            window.location.reload(true);
                        }, 3000);
                        return;
                    }
                    dispatch(getTickets()) // rollback to the most updated data from backend and then updating selected tickets
                        .then(() => {
                            let checkedTickets_copy = cloneDeep(footbar.getList());
                            checkedTickets.forEach((checkedTicket, index) => {
                                tickets.forEach((column) => {
                                    const updatedTicket = column.tickets.find((ticket) => ticket.id === checkedTicket.id)
                                    if (updatedTicket) {
                                        checkedTickets_copy[index] = { ...checkedTicket, ...updatedTicket }
                                    }
                                })
                            })
                            footbar.update({
                                selected: checkedTickets_copy.map((t) => t?.id),
                                list: checkedTickets_copy,
                                type: 'ticket'
                            });
                        })
                });
            setTicketMovement({ movement_type: '', destination: '' });
        }
    }, [ticketMovement]);

    useEffect(() => {
        if (playingSound && notification_sound) {
            const audio = new Audio(notification_sound);
            audio.play();
            dispatch(setPlayingSoundOff());
        }
    }, [playingSound]);

    // Pour mobile
    const selectedStatus = useSelector(state => state.tickets.selectedStatusMobile);
    if (isMobile) {
        const category = tickets.find(ticket => ticket.name === selectedStatus);
        if (category) {
            return (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Component componentName={'LoaderBarTop'} isLoading={isLoadingOnMobile} />
                    <DroppableColumn
                        width={width}
                        key={category?.id}
                        category={category}
                    />
                </DragDropContext>
            );
        }
        return <div />;
    }

    return (
        <>
            <Component componentName={'LoaderBarTop'} isLoading={isLoading} />
            <div className={'ticket-links-container'}>
                {isSupervisorMode && (
                    <div className={'change-view-mode-buttons'}>
                        <Tooltip
                            placement={'top'}
                            arrow={false}
                            title={t('tickets:my_tickets')}
                        >
                            <IconButton
                                onClick={() => {
                                    if (supervisorMode !== 'my_tickets') {
                                        dispatch(getTickets(ticketsActiveMode, isSupervisorMode, 'my_tickets'));
                                        setSupervisorMode('my_tickets');
                                    }
                                }}
                            >
                                <Component componentName={'Icon'} type={supervisorMode === 'my_tickets' ? 'userFilled' : 'user'} size="sm" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            placement={'top'}
                            arrow={false}
                            title={t('tickets:their_tickets')}
                        >
                            <IconButton
                                onClick={() => {
                                    if (supervisorMode !== 'their_tickets') {
                                        dispatch(getTickets(ticketsActiveMode, isSupervisorMode, 'their_tickets'));
                                        setSupervisorMode('their_tickets');
                                    }
                                }}
                            >
                                <Component componentName={'Icon'} type={supervisorMode === 'their_tickets' ? 'userMultipleFilled' : 'userMultiple'} size="sm" />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                <Link
                    className="switch-view-page"
                    underline="hover"
                    onClick={() => {
                        if (ticketsActiveMode) {
                            dispatch(setVisibleTickets('ARCHIVED'));
                            // clean footbar state
                            footbar.close()
                            // We only retrieve the archived tickets once
                            if (!hasLoadedArchivedTicketOnce && tickets.find(cat => cat.id === 'ARCHIVED')?.tickets.length === 0) {
                                dispatch(getTickets(false, isSupervisorMode, supervisorMode));
                                setHasLoadedArchivedTicketOnce(true);
                            } else if (isSupervisorMode) {
                                dispatch(getTickets(false, isSupervisorMode, supervisorMode));
                            }
                        } else {
                            dispatch(setVisibleTickets('DEFAULT'));
                            if (isSupervisorMode) {
                                dispatch(getTickets(true, isSupervisorMode, supervisorMode));
                            }
                        }
                    }}
                >
                    {t(`tickets:see_tickets_${ticketsActiveMode ? 'archived' : 'active'}`)}
                </Link>
            </div>

            {ticketsActiveMode
                ? (
                    <div className={classnames('tickets-container tickets-container-with-top-link')}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            {tickets.filter(cat => cat.id !== 'ARCHIVED').map(category => (
                                <DroppableColumn
                                    width={width}
                                    key={category?.id}
                                    category={category}
                                />
                            ))}
                        </DragDropContext>
                    </div>
                ) : <Component componentName={'ArchivedTicketsTable'} />
            }
        </>
    );
};

export default React.memo(Tickets);
