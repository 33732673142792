// Libs
import React, {
    forwardRef, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
// Context
import { useComponentsPool } from '../../../ComponentsPool';
// Actions
import { setFocusedNavButton, removeFilter } from '../actions';
import { useFootBar } from '../../footbar/FootBarContext';
import { useSecondMainColorTooltip } from '../../layout/helper';

const SearchInput = forwardRef((props, ref) => {
    const { Component } = useComponentsPool();
    const tooltipStyle = useSecondMainColorTooltip();
    const inputRef = useRef(ref);
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const navbar = useSelector(state => state.navbar);
    const pathname = props.history.location.pathname;
    const viewWrapperRef = useRef();
    const [isFocused, setIsFocused] = useState(props.focused);
    const [inputFocused, setInputFocused] = useState(false);
    const currentUser = useSelector(state => state.users.currentUser);
    const metabaseUrl = useSelector(state => state.dashboards.metabaseUrl);
    const disabledSearchInput = window.location.pathname.substring(1)?.includes('dashboards') && !isEmpty(metabaseUrl);
    const footBar = useFootBar();

    const onOutsideClick = () => {
        if (inputFocused) {
            setTimeout(() => {
                setInputFocused(false);
                props.setDisplayResultContainer(false);
            }, 0);
        }
    };

    const goBack = () => { props.history.goBack(); };

    const onKeyDown = () => {
        if (props.query.length === 0) {
            const latestFilter = navbar.filters.slice(navbar.filters.length - 1, navbar.filters.length);
            if (latestFilter.length > 0) {
                dispatch(removeFilter(latestFilter[0].id));
                setTimeout(() => {
                    dispatch(setFocusedNavButton(props.history.location.pathname));
                    inputRef.current.querySelector('.iot-rocket-input-wrapper .MuiInput-input').focus();
                    props.setDisplayResultContainer(true);
                }, 200);
                if (latestFilter[0].type === 'link') {
                    goBack();
                    onOutsideClick();
                    setTimeout(() => {
                        dispatch(setFocusedNavButton(pathname));
                    }, 200);
                }
            } else {
                inputRef.current.querySelector('.iot-rocket-input-wrapper .MuiInput-input').focus();
            }
        }
    };

    const getPlaceholder = () => {
        if (navbar.filters.length > 0) return '';
        const path = window.location.pathname.substring(1);
        if (window.location.pathname === '/devices' && new URLSearchParams(window.location.search).get("view") === 'failures') {
            return isFocused ? t(`settings:search_description`) : t('search:accessto')
        }
        if (props.searchbarName === 'stepsSearchbar') { return t('rounds:search_steps_description'); }
        if (props.searchbarName === 'qrcodesSearchbar') { return t('rounds:search_qrcodes_description'); }
        if (props.searchbarName === 'qrcodesSearchbar') { return t('rounds:search_qrcodes_description'); }
        if (path.includes('dashboards/')) {
            return isFocused ? t('graphs:search_description') : t('search:accessto');
        }
        if (path.includes('tickets')) {
            return isFocused
                ? t(`${path}:search_description_${currentUser.workspace_role?.workspace_role_name?.toLowerCase()}`)
                : t('search:accessto');
        }
        return isFocused ? t(`${path}:search_description`) : t('search:accessto');
    };

    useEffect(() => {
        const handleResize = () => props.setQueryResultWidth(inputRef.current.clientWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    useEffect(() => {
        props.setInputEvent(inputFocused);
        props.setQueryResultWidth(inputRef.current.clientWidth);
        if (inputFocused) {
            setTimeout(() => {
                props.setDisplayResultContainer(props.focused);
            }, 200);
        }
    }, [inputFocused]);

    useEffect(() => {
        props.setQueryResultWidth(inputRef.current.clientWidth);
    }, [isFocused]);

    useEffect(() => {
        if (inputFocused) {
            setTimeout(() => {
                props.setDisplayResultContainer(props.focused);
            }, 200);
        }
        setIsFocused(props.focused);
    }, [props.focused]);

    useEffect(() => {
        props.setDisplayResultContainer(false);
        onOutsideClick();
    }, [navbar.focusedNavButton]);

    useEffect(() => {
        if (!isEmpty(navbar.filters)) {
            props.setDisplayResultContainer(false);
            setIsFocused(true);
        }
    }, [navbar.filters]);

    return (
        <OutsideClickHandler onOutsideClick={() => onOutsideClick()}>
            <Tooltip
                placement={'top'}
                title={disabledSearchInput ? t('dashboards:unavailable_searchbar_cause_metabase') : ''}
            >
                <div
                    ref={inputRef}
                    role={'button'}
                    tabIndex={0}
                    className={classNames('searchbar-input-container', isFocused ? 'maximized' : '')}
                    onClick={() => {
                        if (!disabledSearchInput) {
                            setInputFocused(true);
                            props.expandSearchInput(true);
                        }
                    }}
                >
                    {props.isCustomSearchBar && (
                        <div className={'search-icon'}>
                            <Component componentName={'Icon'} type={'search'} />
                        </div>
                    )}
                    <Component
                        componentName={'NavigationBadge'}
                        isCustomSearchBar={props.isCustomSearchBar}
                        setPathSelectorWidth={props.setPathSelectorWidth}
                        displayRoutesDropdown={props.displayRoutesDropdown}
                        dropdownRoutes={() => props.routesDropdownAction(!props.displayRoutesDropdown)}
                        inputFocused={props.focused}
                    />
                    {navbar.filters.length > 0 && !inputFocused && !props.isCustomSearchBar && <p className={'your-selection'}>{t('common:your_current_selection')}</p>}
                    <div className={'filters-wrapper-container'}>
                        <div className={'filters-wrapper'}>
                            {(props.isCustomSearchBar ? true : !inputFocused) && (
                                <Component
                                    componentName={'Filters'}
                                    isCustomSearchBar={props.isCustomSearchBar}
                                    pathname={pathname}
                                    goBack={() => goBack()}
                                />
                            )}
                            <div
                                className="searchbar-inside-text"
                                style={{ width: '100%' }}
                                ref={viewWrapperRef}
                            >
                                <Component
                                    componentName={'Input'}
                                    required={false}
                                    label={''}
                                    emptyInput={props.emptyInput}
                                    placeholder={getPlaceholder()}
                                    disabled={disabledSearchInput}
                                    onChange={val => {
                                        props.setDisplayResultContainer(true);
                                        props.setQuery(val);
                                    }}
                                    onKeyDown={onKeyDown}
                                    value={props.query}
                                    onFocus={() => {
                                        setInputFocused(true);
                                        props.expandSearchInput(true);
                                        setIsFocused(true);
                                        props.routesDropdownAction(false);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {props.focused && (
                        <Tooltip
                            classes={tooltipStyle}
                            position={'bottom'}
                            title={props.isCustomSearchBar ? t('common:erase') : ''}
                        >
                            <div
                                role={'button'}
                                tabIndex={0}
                                className={'exit-search'}
                                onClick={e => {
                                    e.stopPropagation();
                                    footBar.update({ selected: [], list: [] });
                                    props.exitFocusMode();
                                }}
                            >
                                <Component componentName={'Icon'} type={'close'} />
                                {!props.isCustomSearchBar && <span>{t('common:exit_search')}</span>}
                            </div>
                        </Tooltip>
                    )}
                </div>
            </Tooltip>
        </OutsideClickHandler>
    );
});

export default withRouter(SearchInput);
